import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const testConfig = {
  apiKey: "test-api-key",
  authDomain: "test-project.firebaseapp.com",
  projectId: "test-project",
  storageBucket: "test-project.appspot.com",
  messagingSenderId: "123456789",
  appId: "1:123456789:web:abcdef"
};

const app = initializeApp(
  process.env.REACT_APP_USE_TEST_AUTH ? testConfig : firebaseConfig
);

export const auth = getAuth(app);
export const db = getFirestore(app);

// Credits management functions
export const initializeUserCredits = async (userId) => {
  try {
    console.log('Starting user credits initialization for:', userId);
    const userRef = doc(db, 'users', userId);
    console.log('User reference created');
    
    const userDoc = await getDoc(userRef);
    console.log('User document fetched, exists:', userDoc.exists());
    
    if (!userDoc.exists()) {
      console.log('Creating new user document...');
      await setDoc(userRef, {
        credits: 0,
        totalSongsGenerated: 0,
        createdAt: new Date(),
        lastUpdated: new Date()
      });
      console.log('User document created successfully');
    }
  } catch (error) {
    console.error('Error initializing user credits:', {
      code: error.code,
      message: error.message,
      details: error,
      userId: userId
    });
  }
};

export const addCredits = async (userId, amount) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    credits: increment(amount)
  });
};

export const useCredit = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (userDoc.exists() && userDoc.data().credits > 0) {
    await updateDoc(userRef, {
      credits: increment(-1),
      totalSongsGenerated: increment(1)
    });
    return true;
  }
  return false;
}; 