const packageConfig = {
  starter: {
    name: 'Starter Package',
    credits: 5,
    price: 299, // in cents
    displayPrice: '$2.99',
    features: [
      '5 Songs',
      '$0.60 per song',
      'Basic sharing features'
    ]
  },
  popular: {
    name: 'Popular Package',
    credits: 20,
    price: 999, // in cents
    displayPrice: '$9.99',
    features: [
      '20 Songs',
      '$0.50 per song',
      'Premium sharing features',
      'Referral bonus credits'
    ]
  },
  bulk: {
    name: 'Bulk Package',
    credits: 100,
    price: 3999, // in cents
    displayPrice: '$39.99',
    features: [
      '100 Songs',
      '$0.40 per song',
      'Premium sharing features',
      'Double referral credits',
      'Priority generation'
    ]
  }
};

export default packageConfig; 