import React from 'react';
import packageConfig from '../utils/packageConfig';

const PackageDetails = ({ selectedPackage }) => {
  const packageData = packageConfig[selectedPackage];
  
  if (!packageData) {
    return <div className="text-white">Package not found</div>;
  }
  
  return (
    <div className="text-white">
      <h3 className="text-xl font-bold mb-2">{packageData.name}</h3>
      <p className="text-3xl font-bold mb-4">{packageData.displayPrice}</p>
      <ul className="space-y-2">
        {packageData.features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="text-birthday-yellow mr-2">✨</span>
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PackageDetails; 