export const genreOptions = [
  {
    value: 'Style',
    label: 'Style',
    options: [
      { value: 'Danceable', label: 'Danceable' },
      { value: 'Dance', label: 'Dance' },
      { value: 'Festive', label: 'Festive' },
      { value: 'Groovy', label: 'Groovy' },
      { value: 'Mid-Tempo', label: 'Mid-Tempo' },
      { value: 'Syncopated', label: 'Syncopated' },
      { value: 'Tipsy', label: 'Tipsy' },
      { value: 'Dark', label: 'Dark' },
      { value: 'Atmospheric', label: 'Atmospheric' },
      { value: 'Cold', label: 'Cold' },
      { value: 'Doom', label: 'Doom' },
      { value: 'Dramatic', label: 'Dramatic' },
      { value: 'Sinister', label: 'Sinister' },
      { value: 'Eclectic', label: 'Eclectic' },
      { value: 'Adjunct', label: 'Adjunct' },
      { value: 'Art', label: 'Art' },
      { value: 'Capriccio', label: 'Capriccio' },
      { value: 'Mellifluous', label: 'Mellifluous' },
      { value: 'Nü', label: 'Nü' },
      { value: 'Progressive', label: 'Progressive' },
      { value: 'Unusual', label: 'Unusual' },
      { value: 'Emotion', label: 'Emotion' },
      { value: 'Anthemic', label: 'Anthemic' },
      { value: 'Emotional', label: 'Emotional' },
      { value: 'Happy', label: 'Happy' },
      { value: 'Jubilant', label: 'Jubilant' },
      { value: 'Melancholy', label: 'Melancholy' },
      { value: 'Sad', label: 'Sad' },
      { value: 'Hard', label: 'Hard' },
      { value: 'Aggressive', label: 'Aggressive' },
      { value: 'Banger', label: 'Banger' },
      { value: 'Power', label: 'Power' },
      { value: 'Stadium', label: 'Stadium' },
      { value: 'Stomp', label: 'Stomp' },
      { value: 'Lyrical', label: 'Lyrical' },
      { value: 'Broadway', label: 'Broadway' },
      { value: 'Cabaret', label: 'Cabaret' },
      { value: 'Lounge', label: 'Lounge' },
      { value: 'Operatic', label: 'Operatic' },
      { value: 'Storytelling', label: 'Storytelling' },
      { value: 'Torch-Lounge', label: 'Torch-Lounge' },
      { value: 'Theatrical', label: 'Theatrical' },
      { value: 'Troubadour', label: 'Troubadour' },
      { value: 'Vegas', label: 'Vegas' },
      { value: 'Magical', label: 'Magical' },
      { value: 'Ethereal', label: 'Ethereal' },
      { value: 'Majestic', label: 'Majestic' },
      { value: 'Mysterious', label: 'Mysterious' },
      { value: 'Minimal', label: 'Minimal' },
      { value: 'Ambient', label: 'Ambient' },
      { value: 'Cinematic', label: 'Cinematic' },
      { value: 'Heat', label: 'Heat' },
      { value: 'Slow', label: 'Slow' },
      { value: 'Sparse', label: 'Sparse' },
      { value: 'Party', label: 'Party' },
      { value: 'German Schlager', label: 'German Schlager' },
      { value: 'Glam', label: 'Glam' },
      { value: 'Glitter', label: 'Glitter' },
      { value: 'Soft', label: 'Soft' },
      { value: 'Bedroom', label: 'Bedroom' },
      { value: 'Chillwave', label: 'Chillwave' },
      { value: 'Intimate', label: 'Intimate' },
      { value: 'Sadcore', label: 'Sadcore' },
      { value: 'Weird', label: 'Weird' },
      { value: 'Carnival', label: 'Carnival' },
      { value: 'Distorted', label: 'Distorted' },
      { value: 'Glitchy', label: 'Glitchy' },
      { value: 'Haunted', label: 'Haunted' },
      { value: 'Hollow', label: 'Hollow' },
      { value: 'Musicbox', label: 'Musicbox' },
      { value: 'Random', label: 'Random' },
      { value: 'World/Ethnic', label: 'World/Ethnic' },
      { value: 'Arabian', label: 'Arabian' },
      { value: 'Bangra', label: 'Bangra' },
      { value: 'Calypso', label: 'Calypso' },
      { value: 'Chalga', label: 'Chalga' },
      { value: 'Egyptian', label: 'Egyptian' },
      { value: 'Hindustani', label: 'Hindustani' },
      { value: 'Hōgaku', label: 'Hōgaku' },
      { value: 'Jewish Music', label: 'Jewish Music' },
      { value: 'Klezmer', label: 'Klezmer' },
      { value: 'Matsuri', label: 'Matsuri' },
      { value: 'Middle East', label: 'Middle East' },
      { value: 'Polka', label: 'Polka' },
      { value: 'Russian Navy Song', label: 'Russian Navy Song' },
      { value: 'Suomipop', label: 'Suomipop' },
      { value: 'Tribal', label: 'Tribal' }
    ]
  },
  {
    value: 'Genre',
    label: 'Genre',
    options: [
      { value: 'Country', label: 'Country' },
      { value: 'Appalachian', label: 'Appalachian' },
      { value: 'Bluegrass', label: 'Bluegrass' },
      { value: 'Folk', label: 'Folk' },
      { value: 'Freak Folk', label: 'Freak Folk' },
      { value: 'Western', label: 'Western' },
      { value: 'Dance', label: 'Dance' },
      { value: 'Afro-Cuban', label: 'Afro-Cuban' },
      { value: 'Dance Pop', label: 'Dance Pop' },
      { value: 'Disco', label: 'Disco' },
      { value: 'Dubstep', label: 'Dubstep' },
      { value: 'Disco Funk', label: 'Disco Funk' },
      { value: 'EDM', label: 'EDM' },
      { value: 'Electro', label: 'Electro' },
      { value: 'High-NRG', label: 'High-NRG' },
      { value: 'House', label: 'House' },
      { value: 'Trance', label: 'Trance' },
      { value: 'Downtempo', label: 'Downtempo' },
      { value: 'Ambient', label: 'Ambient' },
      { value: 'Synthwave', label: 'Synthwave' },
      { value: 'Trap', label: 'Trap' },
      { value: 'Electronic', label: 'Electronic' },
      { value: 'Cyberpunk', label: 'Cyberpunk' },
      { value: 'Drum\'n\'bass', label: 'Drum\'n\'bass' },
      { value: 'Hypnogogical', label: 'Hypnogogical' },
      { value: 'IDM', label: 'IDM' },
      { value: 'Phonk', label: 'Phonk' },
      { value: 'Synthpop', label: 'Synthpop' },
      { value: 'Techno', label: 'Techno' },
      { value: 'Jazz/Soul', label: 'Jazz/Soul' },
      { value: 'Bebop', label: 'Bebop' },
      { value: 'Gospel', label: 'Gospel' },
      { value: 'Frutiger Aero', label: 'Frutiger Aero' },
      { value: 'Jazz', label: 'Jazz' },
      { value: 'Latin Jazz', label: 'Latin Jazz' },
      { value: 'RnB', label: 'RnB' },
      { value: 'Soul', label: 'Soul' },
      { value: 'Latin', label: 'Latin' },
      { value: 'Bossa Nova', label: 'Bossa Nova' },
      { value: 'Forró', label: 'Forró' },
      { value: 'Mambo', label: 'Mambo' },
      { value: 'Salsa', label: 'Salsa' },
      { value: 'Tango', label: 'Tango' },
      { value: 'Reggae', label: 'Reggae' },
      { value: 'Afrobeat', label: 'Afrobeat' },
      { value: 'Dancehall', label: 'Dancehall' },
      { value: 'Dub', label: 'Dub' },
      { value: 'Reggaeton', label: 'Reggaeton' },
      { value: 'Metal', label: 'Metal' },
      { value: 'Black Metal', label: 'Black Metal' },
      { value: 'Deathcore', label: 'Deathcore' },
      { value: 'Death Metal', label: 'Death Metal' },
      { value: 'Heavy Metal', label: 'Heavy Metal' },
      { value: 'Heavy Metal Trap', label: 'Heavy Metal Trap' },
      { value: 'Metalcore', label: 'Metalcore' },
      { value: 'Nu Metal', label: 'Nu Metal' },
      { value: 'Power Metal', label: 'Power Metal' },
      { value: 'Popular', label: 'Popular' },
      { value: 'Pop', label: 'Pop' },
      { value: 'Pop Rock', label: 'Pop Rock' },
      { value: 'Kpop', label: 'Kpop' },
      { value: 'Jpop', label: 'Jpop' },
      { value: 'Rock', label: 'Rock' },
      { value: 'Classic Rock', label: 'Classic Rock' },
      { value: 'Blues Rock', label: 'Blues Rock' },
      { value: 'Emo', label: 'Emo' },
      { value: 'Glam Rock', label: 'Glam Rock' },
      { value: 'Hardcore Punk', label: 'Hardcore Punk' },
      { value: 'Indie', label: 'Indie' },
      { value: 'Industrial Rock', label: 'Industrial Rock' },
      { value: 'Punk', label: 'Punk' },
      { value: 'Skate Rock', label: 'Skate Rock' },
      { value: 'Skatecore', label: 'Skatecore' },
      { value: 'Urban', label: 'Urban' },
      { value: 'Funk', label: 'Funk' },
      { value: 'HipHop', label: 'HipHop' },
      { value: 'Rap', label: 'Rap' }
    ]
  },
  {
    value: 'Type',
    label: 'Type',
    options: [
      { value: 'Background', label: 'Background' },
      { value: 'Elevator', label: 'Elevator' },
      { value: 'Jingle', label: 'Jingle' },
      { value: 'Muzak', label: 'Muzak' },
      { value: 'Call to Prayer', label: 'Call to Prayer' },
      { value: 'Adan', label: 'Adan' },
      { value: 'Adjan', label: 'Adjan' },
      { value: 'Gregorian Chant', label: 'Gregorian Chant' },
      { value: 'Character', label: 'Character' },
      { value: 'I Want Song', label: 'I Want Song' },
      { value: 'Hero Theme', label: 'Hero Theme' },
      { value: 'Strut', label: 'Strut' },
      { value: 'March', label: 'March' },
      { value: 'Military', label: 'Military' },
      { value: 'Villain Theme', label: 'Villain Theme' },
      { value: 'Children', label: 'Children' },
      { value: 'Lullaby', label: 'Lullaby' },
      { value: 'Nursery Rhyme', label: 'Nursery Rhyme' },
      { value: 'Sing-along', label: 'Sing-along' },
      { value: 'Toddler', label: 'Toddler' }
    ]
  },
  {
    value: 'Composer',
    label: 'Composer',
    options: [
      { value: 'Adagio', label: 'Adagio' },
      { value: 'Adjunct', label: 'Adjunct' },
      { value: 'Andante', label: 'Andante' },
      { value: 'Allegro', label: 'Allegro' },
      { value: 'Capriccio', label: 'Capriccio' }
    ]
  },
  {
    value: 'Instruments',
    label: 'Instruments',
    options: [
      { value: 'Acoustic Guitar', label: 'Acoustic Guitar' },
      { value: 'Bass', label: 'Bass' },
      { value: 'Doublebass', label: 'Doublebass' },
      { value: 'Electricbass', label: 'Electricbass' },
      { value: 'Electric Guitar', label: 'Electric Guitar' },
      { value: 'Fingerstyle Guitar', label: 'Fingerstyle Guitar' },
      { value: 'Percussion', label: 'Percussion' },
      { value: 'Noise', label: 'Noise' },
      { value: 'Chaotic', label: 'Chaotic' },
      { value: 'Distorted', label: 'Distorted' },
      { value: 'Glitch', label: 'Glitch' },
      { value: 'Random', label: 'Random' },
      { value: 'Stuttering', label: 'Stuttering' },
      { value: 'Orchestral', label: 'Orchestral' },
      { value: 'glissando trombone', label: 'glissando trombone' },
      { value: 'legato cello', label: 'legato cello' },
      { value: 'spiccato violins', label: 'spiccato violins' },
      { value: 'staccato viola', label: 'staccato viola' },
      { value: 'Symphonic', label: 'Symphonic' }
    ]
  },
  {
    value: 'Retro',
    label: 'Retro',
    options: [
      { value: '1960s', label: '1960s' },
      { value: 'Barbershop', label: 'Barbershop' },
      { value: 'Big Band', label: 'Big Band' },
      { value: 'Classic', label: 'Classic' },
      { value: 'Doo Wop', label: 'Doo Wop' },
      { value: 'Girl Group', label: 'Girl Group' },
      { value: 'Mambo', label: 'Mambo' },
      { value: 'Salooncore', label: 'Salooncore' },
      { value: 'Swing', label: 'Swing' }
    ]
  },
  {
    value: 'Traditional',
    label: 'Traditional',
    options: [
      { value: 'Americana', label: 'Americana' },
      { value: 'Barbershop', label: 'Barbershop' },
      { value: 'Christmas Carol', label: 'Christmas Carol' },
      { value: 'Traditional', label: 'Traditional' }
    ]
  },
  {
    value: 'Suffix',
    label: 'Suffix',
    options: [
      { value: '…core', label: '…core' },
      { value: '…jam', label: '…jam' },
      { value: '…out', label: '…out' },
      { value: '…wave', label: '…wave' }
    ]
  },
  {
    value: 'Voice',
    label: 'Voice',
    options: [
      { value: 'A Cappella', label: 'A Cappella' },
      { value: 'Arabian Ornamental', label: 'Arabian Ornamental' },
      { value: 'Dispassionate', label: 'Dispassionate' },
      { value: 'Emotional', label: 'Emotional' },
      { value: 'Ethereal', label: 'Ethereal' },
      { value: 'Gregorian chant', label: 'Gregorian chant' },
      { value: 'Hindustani', label: 'Hindustani' },
      { value: 'Lounge Singer', label: 'Lounge Singer' },
      { value: 'Melismatic', label: 'Melismatic' },
      { value: 'Monotone', label: 'Monotone' },
      { value: 'Narration', label: 'Narration' },
      { value: 'Resonant', label: 'Resonant' },
      { value: 'Spoken Word', label: 'Spoken Word' },
      { value: 'Sprechgesang', label: 'Sprechgesang' },
      { value: 'Sultry', label: 'Sultry' },
      { value: 'Scream', label: 'Scream' },
      { value: 'Torchy', label: 'Torchy' },
      { value: 'Vocaloid', label: 'Vocaloid' }
    ]
  }
];