import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { auth } from './utils/firebase';
import MarketingPage from './components/MarketingPage';
import PaymentForm from './components/PaymentForm';
import Generator from './components/Generator';
import { genreOptions } from './utils/genreOptions';
import AuthModal from './components/AuthModal';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from './utils/firebase';
import Header from './components/Header';
import PackageDetails from './components/PackageDetails';

function App() {
  const [stripePromise, setStripePromise] = useState(null);
  const [step, setStep] = useState('marketing');
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [, setSessionId] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userCredits, setUserCredits] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const { data } = await axios.get('/api/stripe-config');
        const stripe = await loadStripe(data.publishableKey);
        setStripePromise(stripe);
      } catch (error) {
        console.error('Failed to load Stripe:', error);
      }
    };
    
    initializeStripe();
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      if (step !== 'marketing') {
        setStep('marketing');
        setSelectedPackage(null);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [step]);

  useEffect(() => {
    console.log('Setting up auth state listener');
    
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('Auth state changed:', user ? `User ${user.uid} logged in` : 'User logged out');
      
      if (user) {
        setCurrentUser(user);
        console.log('Current user set:', user.uid);
        
        // Set the correct step based on the current route
        if (location.pathname.includes('/generator')) {
          setStep('generator');
        }
        
        try {
          const userRef = doc(db, 'users', user.uid);
          console.log('Created user ref for:', user.uid);
          
          const unsubscribeCredits = onSnapshot(userRef, 
            (doc) => {
              console.log('Credits snapshot received:', doc.exists() ? 'Document exists' : 'No document');
              if (doc.exists()) {
                const credits = doc.data().credits;
                console.log('Setting credits to:', credits);
                setUserCredits(credits || 0);
              } else {
                console.log('No user document found');
                setUserCredits(0);
              }
            },
            (error) => {
              console.error('Credits snapshot error:', error);
            }
          );
          
          return () => {
            console.log('Cleaning up credits listener for:', user.uid);
            unsubscribeCredits();
          };
        } catch (error) {
          console.error('Error in credits setup:', error);
        }
      } else {
        console.log('No user, clearing states');
        setCurrentUser(null);
        setUserCredits(null);
        if (location.pathname.includes('/generator')) {
          navigate('/');
        }
      }
    });

    return () => {
      console.log('Cleaning up auth listener');
      unsubscribe();
    };
  }, [location.pathname, navigate]);

  const handleAuthSuccess = async (user) => {
    try {
      console.log('Auth success, creating user:', user.uid);
      // Call the server endpoint instead of direct Firestore write
      const response = await axios.post('/api/create-user', {
        userId: user.uid
      });
      console.log('User creation response:', response.data);
      
      setShowAuthModal(false);
      if (selectedPackage) {
        setStep('payment');
      }
    } catch (error) {
      console.error('Error in handleAuthSuccess:', error);
      // Continue with the flow even if user creation fails
      setShowAuthModal(false);
      if (selectedPackage) {
        setStep('payment');
      }
    }
  };

  const handleGetStarted = (packageType) => {
    setSelectedPackage(packageType);
    setStep('payment');
  };

  const handleLogin = () => {
    setIsLogin(true);
    setShowAuthModal(true);
  };

  const handlePaymentSuccess = (sessionId, newCredits) => {
    console.log('Payment success handler called:', { sessionId, newCredits });
    setSessionId(sessionId);
    if (newCredits !== undefined) {
      console.log('Setting new credits to:', newCredits);
      setUserCredits(newCredits);
    }
    setStep('generator');
    navigate('/generator');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-birthday-pink via-birthday-blue to-birthday-yellow">
      {showAuthModal && (
        <AuthModal
          isLogin={isLogin}
          packageType={selectedPackage}
          onSuccess={handleAuthSuccess}
          onClose={() => setShowAuthModal(false)}
        />
      )}
      
      <div className="container mx-auto px-4 py-8">
        {step === 'marketing' && (
          <MarketingPage 
            onGetStarted={handleGetStarted} 
            onLogin={currentUser ? () => auth.signOut() : handleLogin}
            currentUser={currentUser}
            userCredits={userCredits}
          />
        )}
        
        {step === 'payment' && (
          <>
            <Header userCredits={userCredits} />
            <div className="container mx-auto px-4 py-4">
              <div className="max-w-6xl mx-auto flex flex-col lg:flex-row gap-6">
                {/* Left side - Package details */}
                <div className="lg:w-1/2">
                  <button 
                    onClick={() => {
                      setStep('marketing');
                      window.history.back();
                    }}
                    className="mb-3 text-white hover:text-birthday-yellow flex items-center"
                  >
                    <span className="mr-1">←</span> Back to packages
                  </button>
                  
                  <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-8 rounded-xl">
                    <h2 className="text-2xl font-bold text-white mb-6">Your Selected Package</h2>
                    <PackageDetails selectedPackage={selectedPackage} />
                  </div>
                </div>

                {/* Right side - Payment form */}
                <div className="lg:w-1/2">
                  <div className="bg-white rounded-xl p-8 shadow-lg">
                    {stripePromise ? (
                      <Elements stripe={stripePromise}>
                        <PaymentForm 
                          onPaymentSuccess={handlePaymentSuccess} 
                          selectedPackage={selectedPackage}
                        />
                      </Elements>
                    ) : (
                      <div className="text-gray-600">Loading payment system...</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        
        {step === 'generator' && (
          <>
            <Header userCredits={userCredits} />
            <div className="container mx-auto px-4 py-8">
              <div className="max-w-6xl mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                  {/* Form Section */}
                  <div className="lg:col-span-7">
                    <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-lg h-full">
                      <h2 className="text-3xl font-bold text-white mb-8 text-center">Create Your Song</h2>
                      <Generator genreOptions={genreOptions} userCredits={userCredits} />
                    </div>
                  </div>
                  
                  {/* Preview/Results Section */}
                  <div className="lg:col-span-5">
                    <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-lg sticky top-8">
                      <h3 className="text-2xl font-bold text-white mb-6">Your Song Preview</h3>
                      {/* Add preview content here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;