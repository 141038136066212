import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { auth } from '../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import packageConfig from '../utils/packageConfig';

const PaymentForm = ({ onPaymentSuccess, selectedPackage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const currentPackage = packageConfig[selectedPackage];
  
  if (!currentPackage) {
    return <div className="text-red-600">Invalid package selected</div>;
  }

  const calculateDiscountedPrice = (originalPrice, coupon) => {
    if (!coupon) return originalPrice;
    
    if (coupon.type === 'percentage') {
      return originalPrice - (originalPrice * (coupon.value / 100));
    } else if (coupon.type === 'fixed') {
      return Math.max(0, originalPrice - coupon.value);
    }
    return originalPrice;
  };

  const handleApplyCoupon = async () => {
    if (!couponCode) return; 
    
    try {
      setError(null);
      // Try to create a payment intent with the coupon to validate it
      const { data } = await axios.post('/api/create-payment-intent', {
        packageType: selectedPackage,
        couponCode: couponCode,
        amount: currentPackage.price
      });

      if (data.skipPayment || data.couponApplied) {
        setAppliedCoupon({
          code: couponCode,
          type: 'percentage',
          value: 100
        });
        setCouponCode(''); // Clear input after successful application
      } else {
        setError('Invalid or expired coupon code');
        setAppliedCoupon(null);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to apply coupon');
      setAppliedCoupon(null);
    }
  };

  const is100PercentOff = appliedCoupon && 
    ((appliedCoupon.type === 'percentage' && appliedCoupon.value === 100) ||
     appliedCoupon.code === 'TEST100' ||
     appliedCoupon.code === 'SHIRIN');

  const handleGoogleAuth = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      if (error.code === 'auth/configuration-not-found') {
        setAuthError('Google sign-in is not properly configured. Please try email signup instead.');
      } else {
        setAuthError('Failed to sign in with Google. Please try again or use email signup.');
      }
      console.error('Google auth error:', error);
      return null;
    }
  };

  const handleEmailSignup = async () => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error) {
      setAuthError(error.message);
      return null;
    }
  };

  const handleEmailLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error) {
      setAuthError(error.message);
      return null;
    }
  };

  const handleFreePackage = async () => {
    if (!auth.currentUser) {
      setError('Please create an account or sign in to continue');
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      const response = await axios.post('/api/purchase-credits', {
        packageType: selectedPackage,
        userId: auth.currentUser?.uid,
        couponCode: appliedCoupon?.code
      });
      
      if (!response.data.success) {
        throw new Error('Failed to add credits');
      }
      
      // Fetch updated credits after successful purchase
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        onPaymentSuccess(response.data.sessionId, userDoc.data().credits || 0);
      }
    } catch (error) {
      setError(error.message || 'Failed to process free package');
      console.error('Free package error:', error);
    }
    
    setProcessing(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (is100PercentOff) {
      await handleFreePackage();
      return;
    }

    setError(null);
    setAuthError(null);
    
    // Check if user is authenticated
    if (!auth.currentUser) {
      setError('Please create an account or sign in to continue');
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      const { data } = await axios.post('/api/create-payment-intent', {
        packageType: selectedPackage,
        couponCode: appliedCoupon ? appliedCoupon.code : null,
        amount: currentPackage.price,
        userId: auth.currentUser?.uid
      });

      if (data.skipPayment) {
        try {
          const response = await axios.post('/api/purchase-credits', {
            packageType: selectedPackage,
            userId: auth.currentUser?.uid,
            couponCode: appliedCoupon?.code
          });
          
          if (!response.data.success) {
            throw new Error('Failed to add credits');
          }
          
          // Fetch updated credits after successful purchase
          const userRef = doc(db, 'users', auth.currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            // Update parent component's credit state
            onPaymentSuccess(data.sessionId, userDoc.data().credits || 0);
          }
          
        } catch (error) {
          setError(error.message || 'Failed to purchase credits');
          console.error('Credit purchase error:', error);
        }
        setProcessing(false);
        return;
      }

      // Only proceed with Stripe payment if not free
      if (!is100PercentOff) {
        const result = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          }
        });

        if (result.error) {
          throw new Error(result.error.message);
        }

        if (result.paymentIntent.status === 'succeeded') {
          await axios.post('/api/confirm-payment', { 
            sessionId: data.sessionId,
            couponApplied: appliedCoupon ? appliedCoupon.code : null,
            userId: auth.currentUser?.uid
          });
          onPaymentSuccess(data.sessionId);
        }
      }
    } catch (err) {
      setError(err.message);
    }
    
    setProcessing(false);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">
        {currentPackage.name} - ${(currentPackage.price / 100).toFixed(2)}
      </h2>
      
      {/* Coupon Section */}
      <div className="mb-4">
        <div className="flex gap-2">
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
            placeholder="Enter coupon code"
            className="flex-1 p-2 border rounded"
          />
          <button
            onClick={handleApplyCoupon}
            disabled={!couponCode || processing}
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
          >
            Apply
          </button>
        </div>
        
        {appliedCoupon && (
          <div className="mt-2 text-green-600">
            Coupon applied: {appliedCoupon.type === 'percentage' ? `${appliedCoupon.value}% off` : `$${(appliedCoupon.value / 100).toFixed(2)} off`}
          </div>
        )}
      </div>

      {/* Auth Section - Show if not logged in */}
      {!auth.currentUser && (
        <div className="mb-6 p-4 border rounded-lg bg-white">
          <h3 className="text-lg font-semibold mb-3">
            {isLogin ? 'Login to Continue' : 'Create Account to Continue'}
          </h3>
          
          <button
            onClick={handleGoogleAuth}
            className="w-full mb-4 p-2 border rounded-lg flex items-center justify-center bg-white hover:bg-gray-50"
          >
            <img 
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/google/google-original.svg" 
              alt=""
              className="w-5 h-5 mr-2" 
            />
            Continue with Google
          </button>

          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with email</span>
            </div>
          </div>

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-2 border rounded mb-2"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-2 border rounded mb-3"
          />
          <button
            onClick={isLogin ? handleEmailLogin : handleEmailSignup}
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            {isLogin ? 'Login' : 'Create Account'}
          </button>
          
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="w-full text-sm text-gray-600 mt-2 hover:text-gray-800"
          >
            {isLogin ? "Don't have an account? Sign up" : "Already have an account? Login"}
          </button>

          {authError && (
            <p className="text-red-500 text-sm mt-2">{authError}</p>
          )}
        </div>
      )}

      {/* Payment Form - Only show if not 100% off */}
      <form onSubmit={handleSubmit}>
        {!is100PercentOff && (
          <div className="mb-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                },
              }}
            />
          </div>
        )}

        {error && (
          <div className="text-red-600 mb-4">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={(!stripe && !is100PercentOff) || processing || !auth.currentUser}
          className={`w-full px-4 py-2 rounded font-bold ${
            processing ? 'bg-gray-300 cursor-not-allowed' : 
            is100PercentOff ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'
          } text-white`}
        >
          {!auth.currentUser 
            ? 'Create account to continue'
            : processing 
              ? 'Processing...' 
              : is100PercentOff 
                ? 'Get Free Package' 
                : `Pay $${((appliedCoupon ? 
                    calculateDiscountedPrice(currentPackage.price, appliedCoupon) : 
                    currentPackage.price) / 100).toFixed(2)}`}
        </button>
      </form>
    </div>
  );
};

export default PaymentForm; 