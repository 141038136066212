import React from 'react';
import { auth } from '../utils/firebase';

const Header = ({ userCredits }) => {
  return (
    <div className="w-full bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
      <div className="container mx-auto px-4 py-3 flex justify-end items-center gap-4">
        <div className="flex items-center gap-2">
          <span className="text-white">Credits:</span>
          <span className="bg-white bg-opacity-20 px-3 py-1 rounded-full text-white">
            {userCredits || 0}
          </span>
        </div>
        <button
          onClick={() => auth.signOut()}
          className="bg-white bg-opacity-20 hover:bg-opacity-30 px-4 py-2 rounded-full text-white"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default Header; 