import React from 'react';
import { FaCheck } from 'react-icons/fa';

const MarketingPage = ({ onGetStarted, onLogin, currentUser }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-birthday-pink via-birthday-blue to-birthday-yellow text-white relative overflow-hidden">
      <div className="container mx-auto px-4 pt-4 pb-8 relative z-10">
        {/* Header */}
        <div className="flex justify-end mb-8">
          <div className="flex items-center gap-4">
            <button
              onClick={onLogin}
              className="bg-white bg-opacity-20 hover:bg-opacity-30 px-4 py-2 rounded-full"
            >
              {currentUser ? 'Sign Out' : 'Sign In'}
            </button>
          </div>
        </div>

        {/* Social Proof Banner */}
        <div className="text-center mb-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg py-2">
          <p className="text-sm">
            <span className="font-bold">🎵 Over 10,000 songs created</span> • 
            <span className="mx-2">⭐️ 4.9/5 from 2,500+ reviews</span>
          </p>
        </div>

        {/* Hero Section */}
        <div className="flex flex-col lg:flex-row items-center justify-between mb-16">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
              Transform Your Story Into a Musical Masterpiece
            </h1>
            <p className="text-xl mb-4 opacity-90">
              Create personalized AI-generated songs that capture your unique essence in minutes. Perfect for birthdays, special occasions, or just because!
            </p>
            {/* Value Props moved directly above package selection */}
            <div className="space-y-2">
              <p className="flex items-center"><FaCheck className="text-birthday-yellow mr-2" /> Professional studio-quality song ($500+ value)</p>
              <p className="flex items-center"><FaCheck className="text-birthday-yellow mr-2" /> Custom lyrics written just for you</p>
              <p className="flex items-center"><FaCheck className="text-birthday-yellow mr-2" /> Unlimited downloads & sharing</p>
            </div>
          </div>
          <div className="lg:w-1/2 relative">
            <div className="rounded-2xl overflow-hidden shadow-2xl max-w-md mx-auto">
              <div className="aspect-w-4 aspect-h-3">
                <img 
                  src={process.env.PUBLIC_URL + "/girl-with-headphones.jpg"} 
                  alt="Girl enjoying music with headphones" 
                  className="w-full h-full object-cover object-center transform scale-90 hover:scale-95 transition-transform duration-300"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Package selection follows immediately */}
        <CreditPackages onSelectPackage={onGetStarted} />
      </div>
    </div>
  );
};

const CreditPackages = ({ onSelectPackage }) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
    <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-xl text-center">
      <h3 className="text-2xl font-bold mb-2">Starter</h3>
      <p className="text-4xl font-bold mb-4">$2.99</p>
      <ul className="text-left mb-4 space-y-2">
        <li>✨ 5 Songs</li>
        <li>💰 $0.60 per song</li>
        <li>🎵 Basic sharing features</li>
      </ul>
      <button 
        onClick={() => onSelectPackage('starter')} 
        className="w-full bg-birthday-yellow text-gray-800 px-4 py-2 rounded-full font-bold hover:bg-white transition-colors duration-300"
      >
        Get Started
      </button>
    </div>
    
    <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-6 rounded-xl text-center transform hover:scale-105 transition-duration-300 relative">
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-birthday-yellow text-gray-800 px-4 py-1 rounded-full text-sm font-bold">
        Most Popular
      </div>
      <h3 className="text-2xl font-bold mb-2">Popular</h3>
      <p className="text-4xl font-bold mb-4">$9.99</p>
      <ul className="text-left mb-4 space-y-2">
        <li>✨ 20 Songs</li>
        <li>💰 $0.50 per song</li>
        <li>🎵 Premium sharing features</li>
        <li>🎁 Referral bonus credits</li>
      </ul>
      <button onClick={() => onSelectPackage('popular')} className="w-full bg-birthday-yellow text-gray-800 px-4 py-2 rounded-full font-bold hover:bg-white transition-colors duration-300">
        Best Value
      </button>
    </div>

    <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-xl text-center transform hover:scale-105 transition-duration-300">
      <h3 className="text-2xl font-bold mb-2">Bulk</h3>
      <p className="text-4xl font-bold mb-4">$39.99</p>
      <ul className="text-left mb-4 space-y-2">
        <li>✨ 100 Songs</li>
        <li>💰 $0.40 per song</li>
        <li>🎵 Premium sharing features</li>
        <li>🎁 Double referral credits</li>
        <li>💫 Priority generation</li>
      </ul>
      <button onClick={() => onSelectPackage('bulk')} className="w-full bg-birthday-yellow text-gray-800 px-4 py-2 rounded-full font-bold hover:bg-white transition-colors duration-300">
        Best Savings
      </button>
    </div>
  </div>
);

export default MarketingPage;