import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { FaUserAlt, FaMusic } from 'react-icons/fa';
import GenreSelector from './GenreSelector';

const Generator = ({ genreOptions, userCredits }) => {
  const [name, setName] = useState('');
  const [uniqueTrait, setUniqueTrait] = useState('');
  const [genres, setGenres] = useState([]);
  const [lyrics, setLyrics] = useState([]);
  const [selectedLyricsIndex, setSelectedLyricsIndex] = useState(null);
  const [songChoices, setSongChoices] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [user, setUser] = useState(auth.currentUser);
  const [sessionId, setSessionId] = useState(null);
  const [generationStatus, setGenerationStatus] = useState(null);
  const [pollInterval, setPollInterval] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const createSession = async () => {
      try {
        const { data } = await axios.post('/api/create-session', {
          userId: user?.uid
        });
        setSessionId(data.sessionId);
      } catch (error) {
        console.error('Error creating session:', error);
        setError('Failed to initialize session. Please refresh the page.');
      }
    };

    if (user) {
      createSession();
    }
  }, [user]);

  useEffect(() => {
    console.log('Current genres state:', genres);
  }, [genres]);

  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [pollInterval]);

  const handleGenerateLyrics = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (lyrics.length >= 5) {
      setError('You have reached the maximum number of lyrics generation attempts.');
      setLoading(false);
      return;
    }

    try {
      console.log('Sending genres to server:', genres);
      const { data } = await axios.post('/api/generate-lyrics', { 
        sessionId, 
        name, 
        uniqueTrait, 
        genres
      });
      console.log('Lyrics generation response:', data);
      setLyrics([...lyrics, data.lyrics]);
      setAttemptsLeft(attemptsLeft - 1);
    } catch (err) {
      console.error('Error generating lyrics:', err);
      setError('Failed to generate lyrics. Please try again.');
    }
    setLoading(false);
  };

  const handleSelectLyrics = async (index) => {
    try {
      console.log('Current genres before selection:', genres);
      if (!genres || genres.length === 0) {
        const defaultGenres = ['pop'];
        console.log('No genres selected, defaulting to:', defaultGenres);
        setGenres(defaultGenres);
        await axios.post('/api/select-lyrics', { 
          sessionId, 
          lyricsIndex: index,
          genres: defaultGenres
        });
      } else {
        console.log('Selecting lyrics with genres:', genres);
        await axios.post('/api/select-lyrics', { 
          sessionId, 
          lyricsIndex: index,
          genres
        });
      }
      setSelectedLyricsIndex(index);
    } catch (err) {
      console.error('Error selecting lyrics:', err);
      setError('Failed to select lyrics. Please try again.');
    }
  };

  const checkSongStatus = async (sessionId) => {
    try {
      const { data } = await axios.get(`/api/song-status/${sessionId}`);
      console.log('Song status response:', data);
      
      if (data.status === 'completed' && data.songChoices) {
        console.log('Song generation completed, updating UI with choices:', data.songChoices);
        setSongChoices(data.songChoices);
        setSelectedSongIndex(0);
        setGenerationStatus('completed');
        setLoading(false);
        clearInterval(pollInterval);
        setPollInterval(null);
      } else if (data.status === 'failed') {
        console.error('Song generation failed:', data.error);
        setError(data.error || 'Failed to generate song. Please try again.');
        setGenerationStatus('failed');
        setLoading(false);
        clearInterval(pollInterval);
        setPollInterval(null);
      } else {
        console.log('Song still processing, status:', data.status);
      }
    } catch (err) {
      console.error('Error checking song status:', err);
      setError('Failed to check song status. Please try again.');
      setLoading(false);
      clearInterval(pollInterval);
      setPollInterval(null);
    }
  };

  const handleGenerateSong = async () => {
    setLoading(true);
    setError(null);
    setSongChoices([]);
    setSelectedSongIndex(null);
    setGenerationStatus('starting');
    console.log('Starting song generation...');
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('User must be logged in to generate a song');
      setLoading(false);
      return;
    }

    if (!sessionId) {
      setError('No session ID found');
      setLoading(false);
      return;
    }

    try {
      console.log('Current genres before song generation:', genres);
      const genresToUse = genres.length > 0 ? genres : ['pop'];
      console.log('Making API request with genres:', genresToUse);
      
      const { data } = await axios.post('/api/generate-song', {
        sessionId,
        userId: currentUser.uid,
        genres: genresToUse
      });
      
      console.log('API response:', data);
      setGenerationStatus('processing');
      
      // Start polling for status and do first check immediately
      await checkSongStatus(sessionId);
      const interval = setInterval(() => checkSongStatus(sessionId), 5000);
      setPollInterval(interval);
      
    } catch (err) {
      console.error('Generation error:', err);
      setError(err.response?.data?.error || 'Failed to generate song. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6 text-white">
      <form onSubmit={handleGenerateLyrics} className="space-y-6">
        <div>
          <label className="block text-lg font-semibold mb-2" htmlFor="name">
            <FaUserAlt className="inline-block mr-2" />
            Recipient's Name
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
            required
            className="w-full px-4 py-3 text-gray-800 rounded-lg shadow-sm focus:ring-2 focus:ring-birthday-blue"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-2" htmlFor="uniqueTrait">
            <FaUserAlt className="inline-block mr-2" />
            Unique Trait
          </label>
          <input
            id="uniqueTrait"
            type="text"
            value={uniqueTrait}
            onChange={(e) => setUniqueTrait(e.target.value)}
            placeholder="Enter a unique trait"
            required
            className="w-full px-4 py-3 text-gray-800 rounded-lg shadow-sm focus:ring-2 focus:ring-birthday-blue"
          />
        </div>

        <div>
          <label className="block text-lg font-semibold mb-2">
            <FaUserAlt className="inline-block mr-2" />
            Select Genres
          </label>
          <GenreSelector 
            genreOptions={genreOptions} 
            onChange={(selectedGenres) => {
              console.log('GenreSelector onChange:', selectedGenres);
              setGenres(selectedGenres);
            }} 
          />
        </div>

        <button 
          type="submit"
          disabled={loading || attemptsLeft === 0}
          className="w-full px-4 py-2 bg-birthday-blue text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800"
        >
          {loading ? 'Generating...' : `Generate Lyrics (${attemptsLeft} attempts left)`}
        </button>
      </form>

      {lyrics.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-bold mb-4">Choose Your Lyrics:</h3>
          {lyrics.map((lyric, index) => (
            <div 
              key={index}
              className={`p-4 mb-4 rounded-md ${
                selectedLyricsIndex === index ? 'bg-birthday-yellow text-gray-800' : 'bg-white bg-opacity-20'
              }`}
            >
              <p className="whitespace-pre-wrap">{lyric}</p>
              <button
                onClick={() => handleSelectLyrics(index)}
                className="mt-2 px-4 py-2 bg-birthday-blue text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800"
              >
                {selectedLyricsIndex === index ? 'Selected' : 'Select These Lyrics'}
              </button>
            </div>
          ))}
        </div>
      )}

        {selectedLyricsIndex !== null && (
        <button
          onClick={handleGenerateSong}
          disabled={loading || !user}
          className="w-full px-4 py-2 bg-birthday-pink text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 disabled:opacity-50"
        >
          {loading ? 
            generationStatus === 'processing' ? 'Generating Song (this may take a few minutes)...' :
            generationStatus === 'starting' ? 'Starting Song Generation...' :
            'Processing...' : 
            !user ? 'Please Sign In to Generate' : 
            'Generate Song'}
        </button>
        )}

      {songChoices.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-bold mb-4">Your Song Preview</h3>
          <div className="space-y-4">
            {songChoices.map((choice, index) => (
              <div 
                key={index}
                className={`p-4 rounded-md ${
                  selectedSongIndex === index ? 'bg-birthday-yellow text-gray-800' : 'bg-white bg-opacity-20'
                }`}
              >
                <div className="flex items-center justify-between mb-2">
                  <h4 className="font-semibold">Version {index + 1}</h4>
                  <span className="text-sm">Duration: {Math.round(choice.duration)}s</span>
                </div>
                <audio 
                  controls 
                  src={choice.url} 
                  className="w-full mb-2"
                  onPlay={() => setSelectedSongIndex(index)}
                />
                <button
                  onClick={() => setSelectedSongIndex(index)}
                  className={`w-full px-4 py-2 ${
                    selectedSongIndex === index 
                      ? 'bg-birthday-yellow text-gray-800' 
                      : 'bg-birthday-blue text-white'
                  } rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors`}
                >
                  <FaMusic className="inline-block mr-2" />
                  {selectedSongIndex === index ? 'Selected' : 'Select This Version'}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-500 bg-opacity-20 backdrop-filter backdrop-blur-lg border border-red-500 text-white rounded-lg p-4 text-center mt-4">
          <p className="font-medium">{error}</p>
        </div>
      )}
    </div>
  );
};

export default Generator; 